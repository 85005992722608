<template>
  <div class="py-3 min-w-0 w-full">
    <span class="display-1">Lainat</span>

    <v-card class="mt-2">
      <v-card-title>
        <v-spacer></v-spacer>
        <router-link
          v-if="isAuthorized('loan', 'create')"
          style="text-decoration: none"
          to="/loan/create"
        >
          <v-btn color="info" class="mb-2">Uusi laina</v-btn>
        </router-link>
      </v-card-title>

      <v-simple-table>
        <template #default>
          <thead>
            <tr>
              <th class="text-left">Nimi</th>
              <th class="text-left">Tyyppi</th>
              <th class="text-left">Alkuperäinen pääoma</th>
              <th class="text-left">Jäljellä oleva pääoma</th>
              <th class="text-left">Kiinteä tasaerä €</th>
              <th class="text-left">Marginaali %</th>
              <th class="text-left">Tämän hetken viitekorko %</th>
              <th class="text-left">Toimenpiteet</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in loans" :key="idx">
              <td class="font-weight-bold">{{ item.name }}</td>
              <td>{{ item.method }}</td>
              <td class="font-weight-bold error--text">{{ formatCurrency(item.totalAmount) }}</td>
              <td class="font-weight-bold success--text">
                {{ formatCurrency(item.currentAmount) }}
              </td>
              <td>{{ formatCurrency(item.annuity) }}</td>
              <td>{{ item.margin }} %</td>
              <td>{{ item.curRefRateInt }} %</td>
              <td>
                <div class="action-grid-container">
                  <v-icon
                    v-if="isAuthorized('loan', 'edit')"
                    small
                    @click="$router.push('/loan/edit/' + item._id)"
                    >mdi-pencil</v-icon
                  >
                  <v-icon v-if="isAuthorized('loan', 'delete')" small @click="deleteItem(item)"
                    >mdi-delete</v-icon
                  >
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p
        v-if="loans.length == 0"
        style="
          font-size: 20px;
          color: rgba(0, 0, 0, 0.38);
          line-height: 29px;
          font-weight: 700;
          padding: 10px 0px 20px 0px;
        "
        class="text-center"
      >
        Ei lainoja
      </p>
    </v-card>
  </div>
</template>

<script>
import axiosMethods from "../../mixins/axios";
import mixins from "../../mixins/mixins";

export default {
  title: "Lainat",

  mixins: [mixins],

  data: () => ({
    loans: [],
  }),

  async created() {
    try {
      let res = await axiosMethods.get("/api/v1/loan/getAll");

      let loans = res.data.loans.map((el) => {
        el.method = this.formatMethod(el.method);
        return el;
      });

      this.loans = loans;
    } catch (err) {
      this.showPopup(err, "error");
    }
  },

  methods: {
    formatMethod(method) {
      if (method == "annuity") return "Annuiteetti";
      if (method == "equal instalment") return "Tasalyhenteinen";
      if (method == "fixed annuity") return "Kiinteä tasaerä";
    },

    editItem(item) {
      this.$router.push("/loan/edit/" + item._id);
    },

    async deleteItem(item) {
      if (confirm("Haluatko varmasti poistaa lainan: " + item.name + "?")) {
        try {
          await axiosMethods.delete("/api/v1/loan/delete/" + item._id);
          this.$router.go();
          this.showPopup("Laina poistettu", "success");
        } catch (err) {
          this.showPopup(err, "error");
        }
      }
    },
  },
};
</script>

<style scoped></style>
